import VueRouter from "vue-router";
import Vue from "vue";

// Coming soon page
// const ComingSoon = () => import(/* webpackChunkName: "ComingSoon" */ '@/components/coming-soon/UnderDevelopment.vue')

const MalaysiaLayout = () => import("@/layouts/MalaysiaLayout.vue")
const GeneralLayout = () => import("@/layouts/GeneralLayout.vue")
const NewLayout = () => import("@/layouts/NewLayout.vue")
const GameLayout = () => import("@/layouts/GameLayout.vue")

// Homepage
const HomePage = () =>
  import(/* webpackChunkName: "Home" */ "@/pages/new-home/Homepage.vue");

// Tracking / Lacak Pengiriman
const Tracking = () =>
  import(
    /* webpackChunkName: "Tracking" */ "@/pages/new-tracking/Tracking.vue"
  );

// Check Price / Cek Tarif
const CheckRates = () =>
  import(
    /* webpackChunkName: "CheckPrice" */ "@/pages/new-check-price/CheckPrice.vue"
  );

// Daftar Agen Homepage
// const AgentHome = () =>
//   import(/* webpackChunkName: "AgentPage22" */ "@/pages/new-agent/Agent.vue");
const AgentLocation = () =>
  import(
    /* webpackChunkName: "AgentLocation" */ "@/pages/new-agent-location/AgentLocation.vue"
  );

// Daftar Korporasi Homepage
const Corporation = () =>
  import(
    /* webpackChunkName: "CorporationPage" */ "@/pages/new-corporation/Corporation.vue"
  );
const CorporationForm = () =>
  import(
    /* webpackChunkName: "CorporationPage" */ "@/pages/new-corporation/form/Form.vue"
  );

// const RegisterIndividualAgent = () =>
//   import(
//     /* webpackChunkName: "AgentRegisterIndividual" */ "@/pages/new-agent/form/individual/Form.vue"
//   );
// const RegisterCompanyAgent = () =>
//   import(
//     /* webpackChunkName: "AgentRegisterCompany" */ "@/pages/new-agent/form/company/Form.vue"
//   );
// // const SuccessRegisterAgent = () => import(/* webpackChunkName: "AgentSuccessRegister" */ '@/pages/agent/registerAgent/SuccessRegister.vue');
// const NewSuccessRegisterAgent = () =>
//   import(
//     /* webpackChunkName: "AgentSuccessRegister" */ "@/pages/new-agent/form/success/Success.vue"
//   );

const AboutPage = () =>
  import(/* webpackChunkName: "About" */ "@/pages/new-about/AboutUspage.vue");
const BoardPage = () =>
  import(
    /* webpackChunkName: "About" */ "@/pages/new-about/board/BoardPage.vue"
  );

// const CareerPage = () => import(/* webpackChunkName: "Career" */ '@/pages/career/careerPage/CareerPage.vue');
// const CareerPageInterview = () => import(/* webpackChunkName: "Career" */ '@/pages/career/careerPage/CareerPageWalkInterview.vue');
// const DetailCareerPage = () => import(/* webpackChunkName: "Career" */ '@/pages/career/detailCareerPage/DetailCareerPage.vue');

const NewCareerPage = () =>
  import(/* webpackChunkName: "Career" */ "@/pages/new-career/Career.vue");
const NewCareerListPage = () =>
  import(
    /* webpackChunkName: "CareerList" */ "@/pages/new-career/jobs/Jobs.vue"
  );
const NewDetailCareerPage = () =>
  import(
    /* webpackChunkName: "CareerDetail" */ "@/pages/new-career/detail/Detail.vue"
  );
const NewCareerForm = () =>
  import(
    /* webpackChunkName: "CareerForm" */ "@/pages/new-career/form/Form.vue"
  );

const NewProgramPage = () =>
  import(/* webpackChunkName: "Promo" */ "@/pages/new-program/Program.vue");
const NewDetailPromoPage = () =>
  import(
    /* webpackChunkName: "Promo" */ "@/pages/new-program/detail/Detail.vue"
  );

const DetailClaimPage = () =>
  import(
    /* webpackChunkName: "Claim" */ "@/pages/new-claim/tracking-claim/index.vue"
  );
const NewClaimPage = () =>
  import(
    /* webpackChunkName: "Claim" */ "@/pages/new-claim/term-condition/index.vue"
  );
const NewFormClaimPage = () =>
  import(
    /* webpackChunkName: "Claim" */ "@/pages/new-claim/form-claim/index.vue"
  );
const NewSuccessClaimPage = () =>
  import(
    /* webpackChunkName: "Claim" */ "@/pages/new-claim/success-claim/index.vue"
  );

const ProductPage = () =>
  import(
    /* webpackChunkName: "Product" */ "@/pages/new-product/Productpage.vue"
  );
const DetailProductPage = () =>
  import(
    /* webpackChunkName: "Product" */ "@/pages/new-product/product-details/ProductDetailPage.vue"
  );

const TNCPage = () =>
  import(/* webpackChunkName: "Others" */ "@/pages/tnc/TermConditionPage.vue");
const PolicyPage = () =>
  import(/* webpackChunkName: "Others" */ "@/pages/policy/PolicyPage.vue");
const SKPage = () =>
  import(/* webpackChunkName: "Others" */ "@/pages/sk/SKPage.vue");
const FAQPage = () =>
  import(/* webpackChunkName: "FAQNew" */ "@/pages/new-faq/FAQPage.vue");
const detailFAQPage = () =>
  import(
    /* webpackChunkName: "DetailFAQ" */ "@/pages/new-faq/detail/detailFAQPage.vue"
  );

// const DangerousGoodsPage = () =>
//   import(
//     /* webpackChunkName: "DangerousGoods" */ "@/pages/new-dangerous-goods/DangerousGoodsPage.vue"
//   );

const KarantinaPage = () =>
  import(
    /* webpackChunkName: "Karantina" */ "@/pages/new-quarantine/Karantina.vue"
  );

const LILOPage = () =>
  import(/* webpackChunkName: "LILO" */ "@/pages/new-lilo/lilo.vue");
const LILOPageSuccess = () =>
  import(/* webpackChunkName: "LILO Success" */ "@/pages/new-lilo/success.vue");

const CODPage = () =>
  import(/* webpackChunkName: "COD" */ "@/pages/new-cod/cod.vue");

const OrderPage = () =>
  import(/* webpackChunkName: "Order" */ "@/pages/order/order.vue");

// this page gonna be use for Mobile App to have shortener link
const ShortenerLink = () =>
  import(
    /* webpackChunkName: "ShortenerLink" */ "@/pages/shortenerLink/shortenerLink.vue"
  );

const RegisterPage = () => import("@/pages/new-register/Register.vue");

const EventAndTips = () =>
  import(
    /* webpackChunkName: "EventAndTips" */ "@/pages/new-event-and-tips/EventAndTips.vue"
  );
const EventAndTipsDetail = () =>
  import(
    /* webpackChunkName: "EventAndTips"*/ "@/pages/new-event-and-tips/detail/Detail.vue"
  );
const MembershipSK = () =>
  import(
    /* webpackChunkName: MembershipSK */ "@/pages/sk/membership/index.vue"
  );
const TermAndConditions = () =>
  import(
    /* webpackChunkName: TermAndConditionsPageMobileApp */ "@/pages/mobile-app/terms-and-conditions/TermsAndConditions.vue"
  );
const OrderSenderForm = () =>
  import(
    /* webpackChunkName: OrderSenderForm*/ "@/pages/order/section/sender-form/sender-form.vue"
  );
const OrderReceiverForm = () =>
  import(
    /* webpackChunkName: OrderReceiverForm*/ "@/pages/order/section/receiver-detail-form/receiver-detail-form.vue"
  );
const OrderShippingPayment = () =>
  import(
    /* webpackChunkName: OrderShippingPayment*/ "@/pages/order/section/shipping-payment/shipping-payment-form.vue"
  );
const OrderSuccess = () =>
  import(
    /* webpackChunkName: OrderSuccess*/ "@/pages/order/section/payment-success/payment-success.vue"
  );

const landingPageMY = () =>
  import(
    /* webpackChunkName: LandingPageMy*/ "@/pages/landing-page-my/landing-page-my.vue"
  );

const CheckRatesMy = () =>
  import (
    /* webpackChunkName: CheckRatesMy */ "@/pages/new-check-price/CheckPriceMy.vue"
  )
  
const DetailClaimPageMY = () =>
  import(
    /* webpackChunkName: "ClaimMy" */ "@/pages/new-claim/tracking-claim-my/index.vue"
  );
const NewClaimPageMY = () =>
  import(
    /* webpackChunkName: "ClaimMy" */ "@/pages/new-claim/term-condition-my/index.vue"
  );
const NewFormClaimPageMY = () =>
  import(
    /* webpackChunkName: "ClaimMy" */ "@/pages/new-claim/form-claim-my/index.vue"
  );
const NewSuccessClaimPageMY = () =>
  import(
    /* webpackChunkName: "ClaimMy" */ "@/pages/new-claim/success-claim-my/index.vue"
  );
  
// Tracking / Lacak Pengiriman
const TrackingMY = () =>
  import(
    /* webpackChunkName: "TrackingMy" */ "@/pages/new-tracking/TrackingMY.vue"
  );

const ProductPageMY = () =>
  import(
    /* webpackChunkName: "ProductMy" */ "@/pages/new-product/ProductpageMY.vue"
  );
const DetailProductPageMY = () =>
  import(
    /* webpackChunkName: "ProductMy" */ "@/pages/new-product/product-details-my/ProductDetailPageMY.vue"
  );

const NewAgent = () =>
  import(
    /* webpackChunkName: "NewAgentRevamp" */ "@/pages/new-agent-revamp/index.vue"
  );
const NewSuccessRegisterAgentRevamp = () =>
  import(
    /* webpackChunkName: "AgentSuccessRegisterRevamp" */ "@/pages/new-agent-revamp/success/Success.vue"
  );

// Game
const GameIntro = () =>
  import(
    /* webpackChunkName: "GameIntro" */ "@/pages/game/introduction.vue"
  );
const GameSpin = () =>
  import(
    /* webpackChunkName: "GameIntro" */ "@/pages/game/spin/spin.vue"
  );
const GameWin = () =>
  import(
    /* webpackChunkName: "GameIntro" */ "@/pages/game/spin/win/win.vue"
  );

// Blog Malaysia
const BlogMalaysia = () =>
  import(
    /* webpackChunkName: "BlogMalaysia" */ "@/pages/new-event-and-tips/BlogMalaysia.vue"
  );
const BlogMalaysiaDetail = () =>
  import(
    /* webpackChunkName: "BlogMalaysia"*/ "@/pages/new-event-and-tips/detail/BlogMalaysiaDetail.vue"
  );

// Update Info
const UpdateInfoRecipient = () =>
  import(
    /* webpackChunkName: "UpdateInfo" */ "@/pages/update-info/recipient/recipient.vue"
  );
const UpdateInfoRecipientSuccess = () =>
  import(
    /* webpackChunkName: "UpdateInfo" */ "@/pages/update-info/success/success-recipient.vue"
  );
const UpdateInfoRecipientUpdated = () =>
  import(
    /* webpackChunkName: "UpdateInfo" */ "@/pages/update-info/updated/updated-recipient.vue"
  );
const UpdateInfoRecipientExpired = () =>
  import(
    /* webpackChunkName: "UpdateInfo" */ "@/pages/update-info/expired/expired.vue"
  );

// Agent Location International
const AgentLocationInt = () =>
  import(
    /* webpackChunkName: "AgentLocationInt" */ "@/pages/agent-location-international/index.vue"
  );

// Aggregator Leaderboard
const AggregatorLeaderboard = () =>
  import(
    /* webpackChunkName: "AggregatorLeaderboard" */ "@/pages/aggregator/Aggregator.vue"
  );

// Dangerous Goods
const DangerousGoods = () =>
  import(
    /* webpackChunkName: "DangerousGoods" */ "@/pages/dangerous-goods/index.vue"
  );

Vue.use(VueRouter);

const routes = [
  //dangerous goods page
  {
    path: "/dangerous-goods",
    name: "Dangerous Goods",
    component: NewLayout,
    children: [
      { path: "/dangerous-goods", component: DangerousGoods },
    ],
    meta: {
      auth: false,
      type: "",
    },
  },
  // aggregator page
  {
    path: "/leaderboard/lincah",
    name: "Aggregator",
    component: NewLayout,
    children: [
      { path: "/leaderboard/lincah", component: AggregatorLeaderboard },
      { path: "/leaderboard/everpro", component: AggregatorLeaderboard },
      { path: "/leaderboard/mengantar", component: AggregatorLeaderboard },
    ],
    meta: {
      auth: false,
      type: "",
    },
  },
  // update info recipient page
  {
    path: "/update-info",
    name: "Update Info",
    component: GeneralLayout,
    children: [
      { path: "/update-info", component: UpdateInfoRecipient },
      { path: "/update-info/success", component: UpdateInfoRecipientSuccess },
      { path: "/update-info/updated", component: UpdateInfoRecipientUpdated },
      { path: "/update-info/expired", component: UpdateInfoRecipientExpired },
    ],
    meta: {
      auth: false,
      type: "",
    },
  },
  // game page
  {
    path: "/ceocard",
    name: "Spin Introduction",
    component: GameLayout,
    children: [
      { path: "/ceocard", component: GameIntro },
      { path: "/ceocard/spin", component: GameSpin },
      { path: "/ceocard/spin/win", component: GameWin },
    ],
    meta: {
      auth: false,
      type: "",
    },
  },
  {
    path: "/my",
    component: MalaysiaLayout,
    children: [
      {
        path: "",
        component: landingPageMY,
        name: "Landing Page Malaysia",
        meta: {
          auth: false,
          type: "",
          lang: "en",
        },
      },
      {
        path: "shipment",
        component: {
          render: (c) => c("router-view"),
        },
        children: [
          { path: "/my/shipment", component: ProductPageMY },
          {
            path: "/my/shipment/:product_name",
            component: DetailProductPageMY,
            props: true,
            name: "Shipment Detail",
          },
        ],
        name: "Shipment",
        meta: {
          auth: false,
          type: "",
          lang: "en",
        },
      },
      {
        path: 'rates',
        component: CheckRatesMy,
        name: "CheckRatesMy", 
        meta: {
          auth: false,
          type: "",
          lang: "en"
        }
      },
      {
        path: "/my/track/stt",
        component: TrackingMY,
        name: "Tracking Shipment Malaysia",
        props: true,
        meta: {
          auth: false,
          type: "",
          lang: "en"
        },
      },
      {
        path: "claim",
        component: () =>
          import(/* webpackChunkName: "Claim" */ "@/pages/new-claim"),
        children: [
          { path: "/my/claim", component: NewClaimPageMY, props: true },
          {
            path: "/my/claim/form",
            component: NewFormClaimPageMY,
            name: "Submit new claims",
            props: true,
          },
          {
            path: "/my/claim/success",
            component: NewSuccessClaimPageMY,
            name: "Successfully submit a new claim",
            props: true,
          },
          {
            path: "/my/claim/track",
            component: DetailClaimPageMY,
            name: "Claim Tracking",
            props: true,
          },
        ],
        name: "Claim",
        meta: {
          auth: false,
          type: "",
          lang: "en"
        },
      },
      {
        path: "blog",
        component: () =>
          import(
            /* webpackChunkName: "BlogMalaysia" */ "@/pages/new-event-and-tips"
          ),
        children: [
          { path: "/my/blog", component: BlogMalaysia },
          {
            path: "/my/blog/:blog_slug",
            name: ":blog_slug",
            component: BlogMalaysiaDetail,
            props: true,
          },
        ],
        name: "Blog",
        meta: {
          auth: false,
          type: "",
        },
      },
    ],
  },
  //new agent page
  {
    path: "/agen",
    name: "Daftar Agen",
    component: NewLayout,
    children: [
      { path: "/agen", component: NewAgent },
      { path: "/agen/registration/submit", component: NewAgent },
      {
        path: "/agen/registration-success/result/",
        component: NewSuccessRegisterAgentRevamp,
        name: "Pendaftaran berhasil",
        props: true,
      },
    ],
    meta: {
      auth: false,
      type: "",
    },
  },
  {
    path: "/",
    component: GeneralLayout,
    children: [
      {
        path: "",
        component: HomePage,
        name: "home",
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "faq",
        component: () =>
          import(/* webpackChunkName: "FAQNew" */ "@/pages/new-faq"),
        children: [
          { path: "/faq", component: FAQPage },
          {
            path: "/faq/:category",
            beforeEnter: (r, _, next) => {
              if (r.params.category === "apps") {
                next({ path: "/faq/apps/pick-up", query: { page: 1 } });
              } else {
                next({
                  path: "/faq/bantuan/jenis-pengiriman-&-layanan",
                  query: { page: 1 },
                });
              }
            },
          },
          {
            path: "/faq/:category/:topic",
            component: detailFAQPage,
            props: true,
            name: ":topic",
          },
        ],
        name: "FAQ",
        meta: {
          auth: false,
          type: "",
        },
      },
      // {
      //   path: "agen",
      //   name: "Agen",
      //   component: {
      //     render: (c) => c("router-view"),
      //   },
      //   children: [
      //     { path: "/agen", component: AgentHome },
      //     {
      //       path: "perorangan-app",
      //       component: RegisterIndividualAgent,
      //       name: "Daftar Perorangan",
      //     },
      //     {
      //       path: "perorangan-app/submit",
      //       component: RegisterIndividualAgent,
      //       name: "Daftar Perorangan",
      //     },
      //     {
      //       path: "perusahaan-app",
      //       component: RegisterCompanyAgent,
      //       name: "Daftar Perusahaan",
      //     },
      //     {
      //       path: "perusahaan-app/submit",
      //       component: RegisterCompanyAgent,
      //       name: "Daftar Perusahaan",
      //     },
      //     {
      //       path: "registration-success/result/",
      //       component: NewSuccessRegisterAgent,
      //       name: "successRegisterAgent",
      //       props: true,
      //     },
      //   ],
      //   meta: {
      //     auth: false,
      //     type: "",
      //   },
      // },
      {
        path: "lokasi-agen",
        component: AgentLocation,
        name: "Lokasi Agen",
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "lokasi-agen/pengiriman-internasional",
        component: AgentLocationInt,
        name: "Lokasi Agen",
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "korporasi",
        component: {
          render: (c) => c("router-view"),
        },
        name: "Korporasi",
        children: [
          { path: "/korporasi", component: Corporation },
          { path: "form", component: CorporationForm, name: "Formulir Bisnis" },
        ],
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "career",
        component: () =>
          import(/* webpackChunkName: "Career" */ "@/pages/new-career"),
        children: [
          { path: "/career", component: NewCareerPage, props: true },
          { path: "/career/jobs", component: NewCareerListPage, props: true },
          // {path: '/career/walk-in', component: CareerPageInterview, props: true},
          {
            path: "/career/detail/:career_id/:position",
            component: NewDetailCareerPage,
            props: true,
            name: ":position",
          },
          {
            path: "/career/form/:career_id",
            component: NewCareerForm,
            props: true,
            name: "Form Lowongan Karir",
          },
        ],
        name: "Karir",
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "about",
        component: () =>
          import(/* webpackChunkName: "About2" */ "@/pages/new-about"),
        children: [
          { path: "/about", component: AboutPage },
          {
            path: "/about/board/:id",
            component: BoardPage,
            props: true,
            name: "Pimpinan Kami",
          },
        ],
        name: "Tentang Kami",
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "product",
        component: {
          render: (c) => c("router-view"),
        },
        children: [
          { path: "/product", component: ProductPage },
          {
            path: "/product/:product_name",
            component: DetailProductPage,
            props: true,
            name: "Detail",
          },
        ],
        name: "Pengiriman",
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "promo",
        component: {
          render: (c) => c("router-view"),
        },
        children: [
          { path: "/promo", component: NewProgramPage },
          {
            path: "/promo/detail/:program_slug",
            component: NewDetailPromoPage,
            props: true,
            name: ":program_slug",
          },
        ],
        name: "Promo List",
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "tarif",
        component: CheckRates,
        name: "Cek Tarif",
        props: true,
        meta: {
          auth: false,
          type: "",
        },
      },
      /*{ path: '/tariff', components: DetailCheckPrice, name: 'tariff', props: true },*/
      {
        path: "track/stt",
        component: Tracking,
        name: "Lacak Pengiriman",
        props: true,
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "klaim",
        component: () =>
          import(/* webpackChunkName: "Claim" */ "@/pages/new-claim"),
        children: [
          { path: "/klaim", component: NewClaimPage, props: true },
          {
            path: "/klaim/form",
            component: NewFormClaimPage,
            name: "Ajukan Klaim Baru",
            props: true,
          },
          {
            path: "/klaim/success",
            component: NewSuccessClaimPage,
            name: "Sukses Ajukan Klaim Baru",
            props: true,
          },
          {
            path: "/klaim/track",
            component: DetailClaimPage,
            name: "Lacak Klaim",
            props: true,
          },
        ],
        name: "Klaim",
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "app/tnc",
        component: TNCPage,
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "app/policy",
        component: PolicyPage,
        meta: {
          auth: false,
          type: "",
        },
      },
      // {
      //   path: "dangerous-goods",
      //   name: "Dangerous Goods",
      //   component: DangerousGoodsPage,
      //   meta: {
      //     auth: false,
      //     type: "",
      //   },
      // },
      {
        path: "sk",
        component: SKPage,
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "register",
        component: RegisterPage,
        name: "Daftar Akun",
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "email/verification/:token",
        component: () =>
          import(
            /* webpackChunkName: "EmailVerification" */ "@/pages/email/index.vue"
          ),
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "karantina",
        name: "Karantina",
        component: KarantinaPage,
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "lilo",
        name: "Fulfillment",
        component: () =>
          import(/* webpackChunkName: "LILO" */ "@/pages/new-lilo/"),
        children: [
          { path: "/lilo", component: LILOPage, props: true },
          {
            path: "/lilo/success",
            component: LILOPageSuccess,
            props: true,
            name: "liloSuccess",
          },
        ],
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "cod",
        name: "COD",
        component: CODPage,
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "pickup-order",
        name: "Kirim Paket",
        redirect: {
          path: "/pickup-order/sender",
        },
        component: OrderPage,
        children: [
          { path: "sender", component: OrderSenderForm, props: true },
          { path: "receiver", component: OrderReceiverForm, props: true },
          {
            path: "summary-payment",
            component: OrderShippingPayment,
            props: true,
          },
        ],
        meta: {
          auth: false,
          type: "",
          typeOrder: 'pickup'
        },
      },
      {
        path: "dropoff-order",
        name: "Kirim Paket",
        redirect: {
          path: "/dropoff-order/sender",
        },
        component: OrderPage,
        children: [
          { path: "sender", component: OrderSenderForm, props: true },
          { path: "receiver", component: OrderReceiverForm, props: true },
          {
            path: "summary-payment",
            component: OrderShippingPayment,
            props: true,
          },
        ],
        meta: {
          auth: false,
          type: "",
          typeOrder: 'dropoff'
        },
      },
      {
        path: "payment-success/:invoice_id",
        name: "Pembayaran Berhasil",
        component: OrderSuccess,
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "info-mitra",
        component: () =>
          import(
            /* webpackChunkName: "EventAndTipsInfoMitra" */ "@/pages/new-event-and-tips"
          ),
        children: [
          { path: "/info-mitra", component: EventAndTips },
          {
            path: "/info-mitra/:corporate_slug",
            name: ":corporate_slug_mitra",
            component: EventAndTipsDetail,
            props: true,
          },
        ],
        name: "Info Mitra",
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "info-seller",
        component: () =>
          import(
            /* webpackChunkName: "EventAndTipsInfoSeller" */ "@/pages/new-event-and-tips"
          ),
        children: [
          { path: "/info-seller", component: EventAndTips },
          {
            path: "/info-seller/:corporate_slug",
            name: ":corporate_slug_seller",
            component: EventAndTipsDetail,
            props: true,
          },
        ],
        name: "Info Seller",
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        // this page gonna be use for Mobile App to have shortener link
        path: "link/:code",
        name: "Shortener Link",
        component: ShortenerLink,
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        // this page gonna be use for Mobile App to have shortener link
        path: "app/t&c",
        name: "Membership SK",
        component: MembershipSK,
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "app/recipient-form/:id",
        name: "Recipient Form",
        component: () =>
          import(
            /* webpackChunkName: "MobileAppRecipientForm" */ "@/pages/mobile-app/recipient-form/RecipientForm.vue"
          ),
        meta: {
          auth: false,
          type: "",
        },
      },
      {
        path: "app/terms-and-conditions/:term_and_cond_id",
        name: "Term and Conditions",
        component: TermAndConditions,
        meta: {
          auth: false,
          type: "",
        },
      },
      { path: "*", redirect: "/" },
    ],
  },

  {
    path: "/*",
    component: () =>
      import(
        /* webpackChunkName: "PageNotFound" */ "@/pages/not-found/NotFound.vue"
      ),
    meta: {
      auth: false,
      type: "",
    },
  },
];
const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
