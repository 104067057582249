import { initializeApp } from 'firebase/app'
import { getRemoteConfig, getValue, fetchAndActivate } from 'firebase/remote-config'

const firebaseConfig = {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    appId: process.env.VUE_APP_FIREBASE_APP_ID,
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Remote Config and get a reference to the service
const remoteConfig = getRemoteConfig(app);
// set min fetch (for cache)
remoteConfig.settings.minimumFetchIntervalMillis = 36000;
// Set in app default values
// remoteConfig.defaultConfig = {
//     "is_hubtohub": false
// };
const event = new Event('firebaseCalled');
fetchAndActivate(remoteConfig)
  .then(() => {
      window.remoteConfig = remoteConfig;
      window.getValue = getValue;
      window.dispatchEvent(event);
      window.isFirebaseLoaded = true;
  })
  .catch(() => {
      console.log('err remote config')
  });
    